const MuiRadio = {
    styleOverrides: {
        root: {
            height: '20px',
            width: '20px',
            border: '2px solid #979797',
            '&.Mui-checked': {
                border: 'none'
            }
        },
        checked: {

        }
    }
};

export default MuiRadio;
